#customerdata {
  padding: 1rem;
  border-top-style: hidden;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#customerdata h3, #customerdata h4 {
  display: none;
}

#booking .substep {
  margin-top: 1rem;
}

#info {
  padding: 1rem;
}

.card-body .DayPicker-Month {
  margin: 0;
}

.card-body .DayPicker-NavButton {
  top: 0;
  right: 0;
}

#pickupDate:disabled {
  background-color: unset;
}

.steps {
  margin-top: 5px;
}

.actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%;
}

.actions li a {
  cursor: pointer;
}

.actions li.disabled a {
  cursor: not-allowed;
}

.input-range__slider, .input-range__track--active {
  background-color: #1ecab8;
  border-color: #1ecab8;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.topbar-main {
  -webkit-box-shadow: none;
  box-shadow: none;
}